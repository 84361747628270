<template>
  <div
    class="ui-card-container ui-card ui-z"
    :class="{'ui-clickable': !!$listeners.click, '--cover': !!cover}"
    @click="$emit('click')"
  >
    <div
      class="ui-card-header"
      :style="style.header"
    >
      <div
        class="ui-card-tint"
        :style="style.tint"
      ></div>

      <ui-item
        class="card-header-item"
        :text="text"
        :secondary="secondary"
        :icon="icon"
      ></ui-item>

      <slot name="header"></slot>
    </div>

    <div class="ui-card-body ui-padded" v-if="$slots.default">
      <slot name="default"></slot>
    </div>

    <div
      class="ui-card-footer ui-padded"
      v-if="$slots.footer"
    >
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import { UiItem } from '@/modules/ui/components';

export default {
  name: 'ui-card',
  components: { UiItem },

  props: {
    cover: {
      type: String,
      required: false,
      default: null,
    },

    color: {
      type: String,
      required: false,
      default: null,
    },

    text: {
      type: [String, Number],
      required: false,
      default: null,
    },

    secondary: {
      type: [String, Number],
      required: false,
      default: null,
    },

    icon: {
      type: String,
      required: false,
      default: null,
    },

    tintOpacity: {
      type: [Number, String],
      required: false,
      default: '0.7',
    },

    coverOffset: {
      type: [Number, String],
      required: false,
      default: 100, // 0-100 (%)
    },
  },

  computed: {
    style() {
      return {
        header: {
          backgroundImage: this.cover ? `url(${this.cover})` : null,
          backgroundPositionY: this.coverOffset + '%',
        },

        tint: {
          backgroundColor: this.color ? this.color : 'var(--ui-color-primary)',
          opacity: this.tintOpacity,
        },
      };
    },
  },
};
</script>

<style lang="scss">
.ui-card-container {
  overflow: hidden;
  border: 0;
  min-width: 200px;

  &.--cover {
    & > .ui-card-header {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      height: 128px;
      background-color: transparent;
      background-repeat: no-repeat;
      background-size: cover;

      padding: 4px;

      .card-header-item {
        color: #fff;
        text-shadow: 0 2px 2px rgba(0, 0, 0, 0.6);
        font-size: 1.7em;
      }

      .ui-card-tint {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        background-color: var(--ui-color-primary);
        opacity: 0.7;
      }
    }
  }
}
</style>